import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'theme/libs/styled';
import colors from 'theme/config/colors';

const Span = styled('span')``;
const Wrapper = styled('div')`
    display: flex;
    align-items: center;

    & > span:nth-of-type(1) {
        font-size: 16px;
        margin: 0 4px 2px 0;
        color: ${colors.inStock};
        font-weight: bold;
    }
`;
const VariantAndPackageStock = ({ isVariant, ...rest }) => {
    const { t } = useTranslation();

    return (
        <Wrapper {...rest}>
            <Span>+</Span>
            <Span>{t(isVariant ? 'is_variant' : 'is_package')}</Span>
        </Wrapper>
    );
};

VariantAndPackageStock.propTypes = {
    isVariant: PropTypes.bool.isRequired,
};

export default VariantAndPackageStock;
