import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';
import { above, below } from 'core-web/util/mediaqueries';
import { injectModel } from 'core-web/state';
import { useTranslation } from 'react-i18next';
import RoundedButton from 'theme/components/buttons/RoundedButton';
import CompareIcon from 'theme/components/icons/CompareIcon';

const Wrapper = styled('div')`
    ${below.lg} {
        button {
            padding: 0;
            width: 28px;
        }
        span {
            display: none;
        }
    }

    ${above.lg} {
        span + svg {
            display: none;
        }
    }
`;

const CompareButton = ({ products, objectId, ...rest }) => {
    const { compareProducts, addCompareProduct, removeCompareProduct } = products;
    const { t } = useTranslation();

    const isBeingCompared = compareProducts.includes(objectId);

    const onClick = () => {
        if (isBeingCompared) {
            removeCompareProduct(objectId);
        } else if (compareProducts.length < 4) {
            addCompareProduct(objectId);
        }
    };

    return (
        <Wrapper {...rest} display={isBeingCompared ? 'block !important' : null}>
            <RoundedButton
                isOutlined={!isBeingCompared}
                size={['small', null, 'large']}
                borderRadius={['50%', null, isBeingCompared ? '50%' : '54px']}
                onClick={onClick}
            >
                {!isBeingCompared && <span>{t('compare')}</span>}
                <CompareIcon />
            </RoundedButton>
        </Wrapper>
    );
};

CompareButton.propTypes = {
    objectId: PropTypes.string.isRequired,
    products: PropTypes.object.isRequired,
};

export default injectModel('products')(CompareButton);
