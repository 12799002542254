import { Component, createElement } from 'react';
import PropTypes from 'prop-types';

class ViewportObserver extends Component {
    observer = null;
    element = null;
    isIntersected = false;

    static defaultProps = {
        tagName: 'div',
        onEnter: null,
        onChange: null,
        onLeave: null,
        root: null,
        rootMargin: '0px',
        threshold: 0,
        disconnectOnEnter: false,
        disconnectOnLeave: false,
    };

    static propTypes = {
        tagName: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]).isRequired,
        onEnter: PropTypes.func,
        onChange: PropTypes.func,
        onLeave: PropTypes.func,
        rootMargin: PropTypes.string,
        threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]).isRequired,
        disconnectOnEnter: PropTypes.bool,
        disconnectOnLeave: PropTypes.bool,
    };

    componentDidMount() {
        const { root, rootMargin, threshold } = this.props;

        this.observer = new IntersectionObserver(this.handleIntersectionCallback, { root, rootMargin, threshold });

        if (this.element && this.observer && this.observer.observe) {
            this.observer.observe(this.element);
        }
    }

    componentWillUnmount() {
        this.removeObserver();
    }

    handleIntersectionCallback = entries => {
        if (entries.length === 0) {
            return;
        }

        const { onEnter, onLeave, onChange, disconnectOnEnter, disconnectOnLeave } = this.props;

        const entry = entries[0];
        const inViewport = entry.intersectionRatio > 0;

        if (onEnter && !this.isIntersected && inViewport) {
            onEnter(this.element);

            if (disconnectOnEnter) {
                this.removeObserver();
            }
        }

        if (onChange) {
            onChange(entry);
        }

        if (onLeave && this.isIntersected && !inViewport) {
            onLeave();

            if (disconnectOnLeave) {
                this.removeObserver();
            }
        }

        this.isIntersected = inViewport;
    };

    removeObserver() {
        if (this.observer) {
            this.observer.unobserve(this.element);
            this.observer.disconnect();
            this.observer = null;
        }
    }

    setElement = element => {
        this.element = element;
    };

    render() {
        // @todo: Maybe a better solution, we only want to the spread ones.
        const {
            tagName,
            onEnter,
            onChange,
            onLeave,
            root,
            rootMargin,
            threshold,
            disconnectOnEnter,
            disconnectOnLeave,
            children,
            ...props
        } = this.props;

        const selfRef = typeof tagName === 'string' ? 'ref' : 'innerRef';

        return createElement(tagName, { ...props, [selfRef]: this.setElement }, children);
    }
}

export default ViewportObserver;
