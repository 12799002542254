import { getState } from 'core-web/state';
import discountInPercentage from 'core-web/util/discountInPercentage';

const showHistoricalPrice = (priceExVat, historicalPriceExVat, priceSetting) => {
    if (!priceSetting) {
        const application = getState('application');
        priceSetting = {
            hideHistoricalPrice: application?.config?.options?.pricing?.hide_historical,
            historicalPriceSettings: application?.config?.options?.pricing?.historical_price_settings,
        };
    }

    if (priceExVat >= historicalPriceExVat) {
        return false;
    }

    const percentage = discountInPercentage(historicalPriceExVat, priceExVat);

    if (priceSetting.hideHistoricalPrice) {
        return false;
    }

    if (!priceSetting.historicalPriceSettings) {
        return parseInt(percentage, 10) >= 1;
    }

    const {
        price_x: priceX,
        percentage_y: percentageY,
        percentage_z: percentageZ,
    } = priceSetting.historicalPriceSettings;

    return (priceExVat < priceX && percentage > percentageY) || (priceExVat > priceX && percentage > percentageZ);
};

export { showHistoricalPrice };
