import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const CompareIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 14 16" {...props}>
        <path
            fill={color}
            d="M13.4 4.4c.1-.2.1-.6 0-.8L10.2.5a.5.5 0 00-.7.7L12.3 4 9.5 6.8a.5.5 0 10.7.7l3.2-3.1zM1 4.5h12v-1H1v1zM.6 11.6c-.1.2-.1.6 0 .8l3.2 3.1a.5.5 0 10.7-.7L1.7 12l2.8-2.8a.5.5 0 10-.7-.7L.6 11.6zm12.4-.1H1v1h12v-1z"
        />
    </SVG>
);

CompareIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

CompareIcon.defaultProps = {
    color: 'currentColor',
    height: '16px',
    width: '14px',
};

export default CompareIcon;
