import { useContext, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Breakpoint } from 'core-web/util/mediaqueries';
import { labels } from 'core-web/components/Breakpoints/breakpoints';
import { getMinBreakpointValue } from 'core-web/components/Breakpoints/calculations';
import { inServer } from 'core-web/constants';

/**
 * Get a boolean if the window media query matches a specific breakpoint
 *
 * @version 1.1
 * @param {string} breakpointLabel - A breakpoint label to match: xs, sm, md etc.
 * @param {bool} updateOnResize - Should the function run on window resize
 * @returns {bool} - Returns true if window matches breakpoint
 */

export default (breakpointLabel, updateOnResize = true) => {
    // Save breakpoint information from server
    const serverBreakpoint = useContext(Breakpoint);
    const serverIndex = serverBreakpoint.breakpointIndex;
    const breakpointIndex = labels.indexOf(breakpointLabel);

    // See if breakpointLabel matches with window breakpoint, used on init and resizing
    // Fallback to false on server because we can not use window
    const checkMatchMedia = () =>
        !inServer ? window.matchMedia(`(min-width: ${getMinBreakpointValue(breakpointLabel)})`).matches : false;

    // MatchIsAbove on init
    const defaultMatchIsAbove = checkMatchMedia();

    // Return value, keeps track if breakpoint is matched or not
    const [isAbove, setIsAbove] = useState(
        inServer && typeof serverIndex === 'number' ? serverIndex >= breakpointIndex : defaultMatchIsAbove
    );

    // MatchIsAbove on resize
    useEffect(() => {
        const resizeHandler = () => {
            // Only update when necessary
            const matchIsAbove = checkMatchMedia();
            if (matchIsAbove !== isAbove) {
                setIsAbove(matchIsAbove);
            }
        };

        const debounceResizeHandler = debounce(resizeHandler, 250);

        // Active resize listener if that is necessary
        if (updateOnResize) {
            window.addEventListener('resize', debounceResizeHandler);
        }
        return () => {
            debounceResizeHandler.cancel();
            window.removeEventListener('resize', debounceResizeHandler);
        };
    }, [isAbove]);

    return isAbove;
};
