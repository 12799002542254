import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from 'theme/config/breakpoints';
import styled from 'theme/libs/styled';
import ViewportObserver from 'theme/components/ViewportObserver';

const { isArray } = Array;

const DEFAULT_QUERY = {
    bg: 'transparent',
    w: null,
    auto: 'format',
    fit: 'clip',
    format: 'jpg',
    q: 80,
};

const toArray = prop => {
    return isArray(prop) ? prop : [prop];
};

const generateRatio = ratios => {
    const slicedBreakpoints = breakpoints.slice(1);
    const ratioArray = isArray(ratios) ? ratios : [ratios];

    const afterStyle = ratio => {
        return {
            '&:after': {
                content: '""',
                display: 'block',
                paddingBottom: isNaN(ratio) ? ratio : `calc(100% / ${ratio})`,
            },
        };
    };

    const style = afterStyle(ratioArray[0]);

    for (let i = 1; i < ratioArray.length; i++) {
        const media = `@media screen and (min-width: ${slicedBreakpoints[i - 1]})`;

        if (ratioArray[i] === null) {
            continue;
        }

        style[media] = afterStyle(ratioArray[i]);
    }

    return style;
};

const generateImages = (images, query) => {
    const style = {};

    const srcArray = toArray(images);
    const widthArray = toArray(query.w);
    const autoArray = toArray(query.auto);
    const fitArray = toArray(query.fit);
    const formatArray = toArray(query.format);
    const qArray = toArray(query.q);
    const bgArray = toArray(query.bg);

    let src = srcArray[0];
    let width = widthArray[0];
    let auto = autoArray[0];
    let fit = fitArray[0];
    let format = formatArray[0];
    let q = qArray[0];
    let bg = bgArray[0];

    const url = (src, width, auto, f, fm, q, bg) => {
        return `url(${src}?${auto ? `auto=${auto}&` : fm ? `format=${fm}&` : ''}q=${q}&fit=${f}${
            width ? `&w=${width}` : ''
        }${bg ? `&bg=${bg}` : ''})`;
    };

    style['backgroundImage'] = url(src, width, auto, fit, format, q, bg);

    const loop = Math.max(
        ...[
            srcArray.length,
            widthArray.length,
            autoArray.length,
            fitArray.length,
            formatArray.length,
            qArray.length,
            bgArray.length,
        ]
    );

    for (let i = 1; i < loop; i++) {
        if (srcArray[i] || widthArray[i] || autoArray[i] || fitArray[i] || formatArray[i] || qArray[i] || bgArray[i]) {
            const media = `@media screen and (min-width: ${breakpoints[i]})`;

            style[media] = style[media] || {};
            src = srcArray[i] || src;
            width = widthArray[i] || width;
            auto = autoArray[i] || auto;
            fit = fitArray[i] || fit;
            format = formatArray[i] || format;
            q = qArray[i] || q;
            bg = bgArray[i] || bg;

            style[media]['backgroundImage'] = url(src, width, auto, fit, format, q, bg);
        }
    }

    return style;
};

const StyledBackgroundImage = styled('div', { shouldForwardProp: prop => ['images', 'ratios'].indexOf(prop) === -1 })`
    ${({ images }) => images};
    ${({ ratios }) => ratios};
`;

const BackgroundImage = ({ loading, placeHolder, alt, src, ratio, query, ...props }) => {
    const images = src && generateImages(src, { ...DEFAULT_QUERY, ...query });
    const ratios = ratio && generateRatio(ratio);

    const backgroundRef = useRef(null);

    const displayBackground = () => {
        if (backgroundRef.current) {
            backgroundRef.current.style.removeProperty('background-image');
        }
    };

    return loading === 'lazy' ? (
        <ViewportObserver
            disconnectOnEnter
            style={{ height: props.height, width: props.width }}
            rootMargin="30%"
            onEnter={displayBackground}
        >
            <StyledBackgroundImage
                images={images}
                ratios={ratios}
                ref={backgroundRef}
                style={{ backgroundImage: 'none' }}
                {...props}
            />
        </ViewportObserver>
    ) : (
        <StyledBackgroundImage images={images} ratios={ratios} {...props} />
    );
};

BackgroundImage.propTypes = {
    alt: PropTypes.string,
    backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    backgroundPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    backgroundRepeat: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    backgroundSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    loading: PropTypes.string,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    placeHolder: PropTypes.string,
    query: PropTypes.shape({
        auto: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        fit: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        w: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
        bg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
    ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

BackgroundImage.defaultProps = {
    alt: null,
    backgroundColor: 'transparent',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    loading: 'lazy',
    maxHeight: null,
    maxWidth: null,
    placeHolder: null,
    query: null,
    ratio: null,
    width: '100%',
};

export default BackgroundImage;
