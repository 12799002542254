import React from 'react';
import Media from 'react-media-universal';
import PropTypes from 'prop-types';
import { Breakpoint } from 'core-web/util/mediaqueries';

import { getMinBreakpointValue } from './calculations';
import { labels } from './breakpoints';

const Above = ({ breakpoint, render }) => (
    <Breakpoint.Consumer>
        {({ breakpointIndex }) => (
            <Media
                query={{ minWidth: getMinBreakpointValue(breakpoint) }}
                defaultMatches={breakpointIndex >= labels.indexOf(breakpoint)}
                render={render}
            />
        )}
    </Breakpoint.Consumer>
);

Above.propTypes = {
    breakpoint: PropTypes.oneOf(labels).isRequired,
    render: PropTypes.func.isRequired,
    children: (props, propName, componentName) => {
        if (propName in props) {
            return new Error(`Using children in Above is deprecated. Use render prop instead.`);
        }
    },
};

export default Above;
